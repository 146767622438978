<script>
  import { onMount, onDestroy } from "svelte";
  export let pageId;
  export let authenticityToken;

  let relativeEditorContainer;
  let titleSection
  let pageTitle
  let timeoutId
  let pageTitleInputIsFocused = false
  let pagesSearchResults = []
  let relativePages
  let toAddRelativePage = []
  let toRemoveRelativePage = []
  

  async function searchPages() {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(async () => {
      if (pageTitle.trim() === "") return;

      try {
        const response = await fetch(`/pages/search_by_title?query=${encodeURIComponent(pageTitle)}`);
        if (!response.ok) throw new Error("Erro na requisição");

        pagesSearchResults = await response.json();
        pagesSearchResults = pagesSearchResults.filter(page => !relativePages.some(relativePage => relativePage.id === page.id))
      } catch (error) {
        console.error(error);
      }
    }, 500);
  }

  function closeTitleInput() {
    setTimeout(() => {
      pageTitleInputIsFocused = false;
      pagesSearchResults = [];
    }, 200);
  }

  async function loadRelativePages() {
    try {
      const response = await fetch(`/pages/${pageId}/relatives`);
      if (!response.ok) throw new Error("Erro na requisição");

      const { title_section, relatives } = await response.json();
      relativePages = relatives;
      titleSection = title_section;
    } catch (error) {
      console.error(error);
    }
  }

  async function removeRelative(page, isCurrentRelative){
    if(isCurrentRelative){
      toRemoveRelativePage = [...toRemoveRelativePage, page]
    }else{
      toAddRelativePage = toAddRelativePage.filter(relative => relative.id !== page.id)
    }
  }

  async function handleSubmit(){
    await fetch(`/pages/${pageId}/update_relatives`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticityToken
      },
      body: JSON.stringify({ 
        page: { 
          title_section: titleSection, 
          toAddRelativesIds: toAddRelativePage.map((relative) => {return relative.id}), 
          toRemoveRelativesIds: toRemoveRelativePage.map((relative) => {return relative.id}) 
        } 
      })
    })
    window.location.href = `/pages/${pageId}/edit`
  }

  function addPageToRelativePages(page, redo=false){
    if(redo){
      toRemoveRelativePage = toRemoveRelativePage.filter(relative => relative.id !== page.id)
    }else{
      toAddRelativePage = [...toAddRelativePage, page]
    }
    pageTitle = ""
    pagesSearchResults = []
  }

  onDestroy(() => clearTimeout(timeoutId));
  onMount(async() => {
    relativeEditorContainer = document.getElementById("relatives-editor")
    await loadRelativePages()
  });
</script>

<form on:submit|preventDefault={handleSubmit} class="position-fixed d-flex justify-content-center align-items-center top-0 start-0 w-100 h-100">
  <div style="gap: 0.75rem;" class="bg-white p-5 rounded border d-flex w-50 flex-column" >
    <div class="d-flex justify-content-between">
      <h4>Editar paginas relativas</h4>
      <button on:click={() => relativeEditorContainer.classList.toggle('d-none')} type="button" class="btn-close" aria-label="Close"></button>
    </div>
    <div>
      <label for="title_section">Titulo da seção</label>
      <input bind:value={titleSection} type="text" id="title_section" name="title_section" class="form-control">
    </div>
    <div class="position-relative">
      <label for="page_title">Adicionar páginas relativas</label>
      <input bind:value={pageTitle} on:input={searchPages} on:blur={closeTitleInput} on:focus={() => pageTitleInputIsFocused = true} type="text" id="page_title" name="page_title" class="form-control">
      <div class="container-search">
        {#if pageTitleInputIsFocused}
          {#if pagesSearchResults.length > 0}
            {#each pagesSearchResults as page}
              <div class="p-2 border-bottom" style="cursor: pointer;" on:click={() => addPageToRelativePages(page)}>
                {page.title}
              </div>
            {/each}
          {:else}
            <div class="p-2">Nenhum resultado encontrado</div>
          {/if}
        {/if}
      </div>
    </div>

    <h5 class="mt-2">Páginas relativas atuais</h5>
    <div class="d-flex flex-column">
      {#if relativePages && relativePages.length > 0}
        <ul class="list-group mt-2">
          {#each relativePages as relativePage}
            {#if toRemoveRelativePage.some(page => page.id === relativePage.id)}
              <li class="p-2 bg-danger text-white d-flex w-100 justify-content-between align-items-center text-decoration-line-through" style="cursor: pointer;">
                {relativePage.title}
                <span on:click={() => addPageToRelativePages(relativePage, true)} type="button" aria-label="Close"><i class="text-white fa fa-repeat"></i></span>
              </li>
            {:else}
              <li class="p-2 d-flex w-100 justify-content-between align-items-center" style="cursor: pointer;">
                {relativePage.title}
                <button on:click={() => removeRelative(relativePage, true)} type="button" class="btn-close" aria-label="Close"><i class="fas fa-redo"></i></button>
              </li>
            {/if}
          {/each}
        </ul>
      {/if}
      {#if toAddRelativePage && toAddRelativePage.length > 0}
        <ul class="list-group">
          {#each toAddRelativePage as relativePage}
            <li class="p-2 bg-success text-white d-flex w-100 justify-content-between" style="cursor: pointer;">
              {relativePage.title}
              <button on:click={() => removeRelative(relativePage, false)} type="button" class="btn-close" aria-label="Close"></button>
            </li>
          {/each}
        </ul>
      {/if}
    </div>
    <button type="submit" class="btn btn-primary">Salvar alterações</button>
  </div>
</form>

<style>
  .container-search{
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 0.5rem 0.5rem;
    max-height: 200px;
    overflow-y: auto;
  }
</style>