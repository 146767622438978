import App from '../svelte/relatives_editor.svelte'

document.addEventListener('turbo:load', () => {
  const editor = document.getElementById('relatives-editor');
  const crfToken = document.querySelector('meta[name="csrf-token"]').content;
  
  if(editor){
    const app = new App({
      target: editor,
      props: { 
        authenticityToken: crfToken,
        pageId: editor.dataset.pageid,
      }
    });

    window.app = app;
  }
})
